import { z } from 'zod';

export const headerSchema = z.array(
  z.object({
    line_number: z.number(),
    override_text: z.string(),
    default_text: z.string(),
  }),
);

export const footerSchema = z.array(
  z.object({
    line_number: z.number(),
    override_text: z.string(),
    default_text: z.string(),
  }),
);

export const getCustomReceiptTextSchema = z.object({
  header: headerSchema,
  footer: footerSchema,
});

type CustomReceiptTextResponse = z.infer<typeof getCustomReceiptTextSchema>;

export default CustomReceiptTextResponse;
