import { z } from 'zod';

export const color = z.object({
  colorId: z.number(),
  colorName: z.string(),
  rgb: z.string(),
});

type Color = z.infer<typeof color>;

export const getLocalItemsColorSchema = z.array(color);

export default Color;
