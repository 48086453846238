import { z } from 'zod';

export const localItemButton = z.object({
  buttonBackgroundColor: z.string(),
  buttonTextColor: z.string(),
  buttonTextString: z.string(),
  buttonType: z.number(),
  corporateItemFlag: z.number(),
  taxCategoryId: z.number(),
  deviceGroupId: z.number(),
  name: z.string(),
  price: z.number(),
  receiptText: z.string(),
  retailItemId: z.number(),
  retailModifiedItemId: z.number(),
  visibility: z.number(),
});

type LocalItemButtonSchema = z.infer<typeof localItemButton>;

export default LocalItemButtonSchema;
