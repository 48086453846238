import { z } from 'zod';
import MenuItemOverrides, {
  MenuItemOverride,
  getMenuItemOverridesResponseSchema,
} from '../types/responses/MenuItemOverrides';
import ItemLockCartChange from '../types/ItemLockCartChange';
import QuickAction, { quickActionSchema } from '../types/responses/QuickAction';
import ItemCondiment, {
  itemCondimentSchema,
} from '../types/responses/ItemCondiment';
import AutoSellItem, {
  autoSellItemSchema,
} from '../types/responses/AutoSellItem';
import Condiment, { condimentSchema } from '../types/responses/Condiment';
import ItemPriceCartChange from '../types/ItemPriceCartChange';
import AutoSellCartChange from '../types/AutoSellCartChange';
import CustomNameCartChange from '../types/CustomNameCartChange';
import CustomItemName, {
  getCustomItemNamesResponseSchema,
} from '../types/responses/CustomItemName';
import LocalItems, { getLocalItemsSchema } from '../types/responses/LocalItems';
import LocalItemButton, {
  localItemButton,
} from '../types/responses/LocalItemButton';
import Color, {
  getLocalItemsColorSchema,
} from '../types/responses/LocalItemsColors';
import AppConstants from '../AppConstants';
import env from '../env';
import CustomReceiptTextResponse, {
  getCustomReceiptTextSchema,
} from '../types/responses/CustomReceiptText';
import CdliItems, { getCdliItemsSchema } from '../types/responses/CdliItems';
import LocalItemButtonData from '../types/responses/LocalItemButtonData';
import TaxCategorySchema, { taxCategory } from '../types/responses/TaxCategory';
import DeviceGroupsList, {
  deviceGroupsListSchema,
} from '../types/responses/DeviceGroupsList';
import StoreVenue from '../types/responses/StoreVenue';
import createApiClient from './createApiClient';

const baseUrlMap: { [key: string]: string } = {
  [AppConstants.REACT_APP_ENVIRONMENT.DEV]:
    'https://api.menuintegration-dev.cfadevelop.com',
  [AppConstants.REACT_APP_ENVIRONMENT.TEST]:
    'https://test-api.menuintegration-dev.cfadevelop.com',
  [AppConstants.REACT_APP_ENVIRONMENT.STAGE]:
    'https://api.menuint-uat.cfadevelop.com',
  [AppConstants.REACT_APP_ENVIRONMENT.PROD]:
    'https://api.menuintegration.cfahome.com',
};

const baseUrl = baseUrlMap[env.REACT_APP_ENVIRONMENT];

const apiClient = createApiClient(baseUrl);

const menuIntService = {
  getMenuItemOverrides: (locationNumber: string, venueId?: string) => {
    const venuePath = venueId ? `/${venueId}/lock` : '';
    return apiClient
      .get(`api/overrides/${locationNumber}${venuePath}`, {
        validationSchema: getMenuItemOverridesResponseSchema,
      })
      .json<MenuItemOverrides>();
  },
  publishMenuItemOverrides: (
    locationNumber: string,
    venueId: string,
    venueList: StoreVenue[],
    overrides: ItemLockCartChange[],
  ) => {
    const venues = [venueId, ...venueList.map(venue => venue.venue_id)];
    return apiClient
      .post(`api/${locationNumber}/lock`, {
        json: { items: overrides, venues: venues },
      })
      .json<MenuItemOverrides>();
  },
  saveMenuItemOverrides: ({
    locationNumber,
    overrides,
    emergencyLockTag,
    overrideLockType,
    effectiveStartDate,
  }: {
    locationNumber: string;
    overrides: MenuItemOverride[];
    emergencyLockTag?: string;
    overrideLockType?: string;
    effectiveStartDate?: string;
  }) => {
    return apiClient
      .post(`api/overrides/${locationNumber}`, {
        json: {
          locations: [locationNumber],
          overrides,
          emergencyLockTag,
          overrideLockType,
          effectiveStartDate,
        },
      })
      .json<MenuItemOverrides>();
  },
  getQuickActions: (locationNumber: string) =>
    apiClient
      .get(`api/quick-actions/${locationNumber}`, {
        validationSchema: z.array(quickActionSchema),
      })
      .json<QuickAction[]>(),
  getAutoSellItems: (locationNumber: string) =>
    apiClient
      .get(`api/autoSellCondiments/${locationNumber}/itemCondiments`, {
        validationSchema: z.array(itemCondimentSchema),
      })
      .json<ItemCondiment[]>(),
  getItemsCondiments: (locationNumber: string, itemId: number) =>
    apiClient
      .get(`api/autoSellCondiments/${locationNumber}/${itemId}/condiments`, {
        validationSchema: z.array(condimentSchema),
      })
      .json<Condiment[]>(),
  getAutoSellCondiments: (locationNumber: string) =>
    apiClient
      .get(`api/autoSellCondiments/${locationNumber}/all-condiments`, {
        validationSchema: z.array(condimentSchema),
      })
      .json<Condiment[]>(),
  getCondimentsItems: (locationNumber: string, condimentId: number) =>
    apiClient
      .get(`api/autoSellCondiments/${locationNumber}/${condimentId}/items`, {
        validationSchema: z.array(autoSellItemSchema),
      })
      .json<AutoSellItem[]>(),
  saveAutoSellChanges: ({
    locationNumber,
    cart,
  }: {
    locationNumber: string;
    cart: AutoSellCartChange[];
  }) =>
    apiClient
      .put(`api/autoSellCondiments/${locationNumber}/itemCondiments`, {
        headers: {
          'Return-Route': '/menu/autoSellCondiments',
        },
        json: cart,
      })
      .json(),
  getMenuItemPriceOverrides: (locationNumber: string, venueId?: string) => {
    const venuePath = venueId ? `/${venueId}/price` : '/price';
    return apiClient
      .get(`api/overrides/${locationNumber}${venuePath}`, {
        validationSchema: getMenuItemOverridesResponseSchema,
      })
      .json<MenuItemOverrides>();
  },
  getCustomItemNames: (locationNumber: string) => {
    return apiClient
      .get(`api/customItemText/${locationNumber}`, {
        validationSchema: getCustomItemNamesResponseSchema,
      })
      .json<CustomItemName>();
  },
  getCustomReceiptText: (locationNumber: string) => {
    return apiClient
      .get(`api/receipt/overrides/${locationNumber}/P001`, {
        validationSchema: getCustomReceiptTextSchema,
      })
      .json<CustomReceiptTextResponse>();
  },
  getLocalItems: (locationNumber: string) => {
    return apiClient
      .get(`api/local-items/${locationNumber}/`, {
        validationSchema: getLocalItemsSchema,
      })
      .json<LocalItems>();
  },
  getLocalItemDetail: (
    locationNumber: string,
    frameId: number,
    frameButtonId: number,
  ) => {
    return apiClient
      .get(`api/local-items/${locationNumber}/${frameId}/${frameButtonId}`, {
        validationSchema: localItemButton,
      })
      .json<LocalItemButton>();
  },
  getLocalItemsColors: () => {
    return apiClient
      .get(`api/local-items/pos-colors`, {
        validationSchema: getLocalItemsColorSchema,
      })
      .json<Color[]>();
  },
  getCdliItems: (locationNumber: string) => {
    return apiClient
      .get(`api/local-items/corporateItems/${locationNumber}`, {
        validationSchema: getCdliItemsSchema,
      })
      .json<CdliItems>();
  },
  getTaxCategory: () => {
    return apiClient
      .get(`api/local-items/tax-categories`, {
        validationSchema: taxCategory,
      })
      .json<TaxCategorySchema>();
  },

  getDeviceGroup: () => {
    return apiClient
      .get(`api/pos-option-groups/device-groups`, {
        validationSchema: deviceGroupsListSchema,
      })
      .json<DeviceGroupsList>();
  },

  publishLocalItems: (locationNumber, params) =>
    apiClient
      .patch(
        `api/local-items/${locationNumber}/${params.menuFrame}/${params.menuFrameButton}`,
        {
          json: params.localItemDetail,
        },
      )
      .json<LocalItemButtonData>(),

  deleteLocalItems: (locationNumber, menuFrame, menuFrameButton) =>
    apiClient
      .delete(
        `api/local-items/${locationNumber}/${menuFrame}/${menuFrameButton}`,
      )
      .json(),
  saveCustomReceiptText: (
    locationNumber: string,
    customReceiptText: CustomReceiptTextResponse,
  ) =>
    apiClient
      .put(`api/receipt/overrides/${locationNumber}/P001`, {
        json: customReceiptText,
      })
      .json(),
  saveQuickAction: (
    locationNumber: string,
    { toggleTag, status }: QuickAction,
  ) =>
    apiClient
      .put(`api/quick-actions/${locationNumber}/status/${toggleTag}`, {
        json: { status },
      })
      .json(),
  saveItemLockChanges: ({
    locationNumber,
    cart,
  }: {
    locationNumber: string;
    cart: ItemLockCartChange[];
  }) =>
    apiClient
      .put(`api/pricing/${locationNumber}/cart/lock`, {
        headers: {
          'Return-Route': '/menu/itemLock',
        },
        json: { cart },
      })
      .json(),
  saveCustomNameChanges: ({
    locationNumber,
    cart,
  }: {
    locationNumber: string;
    cart: CustomNameCartChange[];
  }) =>
    apiClient
      .put(`api/carts/${locationNumber}/customItemText`, { json: { cart } })
      .json(),
  saveItemPriceChanges: ({
    locationNumber,
    cart,
  }: {
    locationNumber: string;
    cart: ItemPriceCartChange[];
  }) =>
    apiClient
      .put(`api/carts/${locationNumber}/price`, { json: { cart } })
      .json(),
  publishMenuPriceOverrides: (
    locationNumber: string,
    venueId: string,
    venueList: StoreVenue[],
    overrides: ItemPriceCartChange[],
  ) => {
    const venues = [venueId, ...venueList.map(venue => venue.venue_id)];
    return apiClient
      .post(`api/${locationNumber}/price`, {
        json: { items: overrides, venues: venues },
      })
      .json();
  },
  publishChanges: (locationNumber: string, dataPoints: string[]) =>
    apiClient
      .post(`api/publish/carts/${locationNumber}/P001`, {
        json: dataPoints,
      })
      .json(),
  revertChanges: (locationNumber: string, dataPoints: string[]) =>
    apiClient
      .delete(`api/revert/carts/${locationNumber}`, {
        json: dataPoints,
      })
      .json(),
} as const;

export default menuIntService;
