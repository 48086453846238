import { z } from 'zod';
import { condimentSchema } from './Condiment';

export const itemCondimentSchema = z.object({
  condimentList: z.array(condimentSchema),
  retailModifiedItemId: z.number(),
  retailModifiedItemName: z.string(),
});

type ItemCondiment = z.infer<typeof itemCondimentSchema>;

export default ItemCondiment;
