import ky from 'ky';
import { getAccessToken } from '@cfacorp/ctrl-platform-ui-core-utils';
import logErrorToBugsnag from '../utils/logErrorToBugsnag';

const createApiClient = (baseUrl: string) => {
  return ky.create({
    timeout: 30000,
    prefixUrl: baseUrl,
    retry: {
      limit: 2,
      statusCodes: [401, 403, 504, 500],
    },
    hooks: {
      beforeRequest: [
        request => {
          request.headers.set('Authorization', `Bearer ${getAccessToken()}`);
          request.headers.set('Return-Route', window.location.pathname);
        },
      ],
      beforeError: [
        async error => {
          const errorJson = await error.response.json();
          logErrorToBugsnag(`DOP - API Error- - ${error.request.url}`, {
            response: error,
            context: 'Base API Client',
          });
          return errorJson;
        },
      ],
    },
  });
};

export default createApiClient;
