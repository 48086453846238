import { z } from 'zod';
import { condimentSchema } from '../responses/Condiment';

const noTabsRegex = new RegExp(/^[^\t]+$/);

export const customItemNameOverrideSchema = z.object({
  retailModifiedItemId: z.number(),
  name: z.string(),
  retailItemId: z.number(),
  customTextOverride: z.string().regex(noTabsRegex, 'Cannot contain tabs'),
  defaultText: z.string(),
  overrideCustomTextStatusStart: z.string(),
  overrideCustomTextStatusEnd: z.string(),
  itemTextOverrideUpdatedBy: z.string(),
  updatedTimeStamp: z.string(),
  masterMenuLock: z.boolean(),
  adminSuperLock: z.boolean(),
  condimentGroup: z.array(condimentSchema),
  customTextNameOverride: z.string(),
});

export type CustomItemName = z.infer<typeof customItemNameOverrideSchema>;

export const getCustomItemNamesResponseSchema = z.object({
  locationNumber: z.string(),
  overrides: z.array(customItemNameOverrideSchema),
});

type CustomItemNameResponseSchema = z.infer<
  typeof getCustomItemNamesResponseSchema
>;

export default CustomItemNameResponseSchema;
