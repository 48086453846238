import { useEffect, useState } from 'react';
import { useUserPermissions } from '@cfacorp/ctrl-platform-shared-react-components';

const useHasPosPerms = () => {
  const cfaPerms = useUserPermissions();
  const [hasPosPerms, setHasPosPerms] = useState(true);
  const [posPermsLoaded, setPosPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAuthorized =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.POS_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.POS_USER?.length;
      setHasPosPerms(isAuthorized);
      setPosPermsLoaded(true);
    }
  }, [cfaPerms]);

  return { hasPosPerms, posPermsLoaded };
};

export default useHasPosPerms;
