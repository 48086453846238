import { z } from 'zod';

export const condimentSchema = z.object({
  condimentId: z.number(),
  condimentName: z.string(),
  condimentRetailId: z.number(),
  imgUrl: z.string().optional(),
});

type Condiment = z.infer<typeof condimentSchema>;

export default Condiment;
