import { z } from 'zod';

export const deviceGroupsListSchema = z.array(
  z.object({
    deviceGroupId: z.number(),
    deviceGroupName: z.string(),
    active: z.boolean(),
  }),
);

type DeviceGroupsList = z.infer<typeof deviceGroupsListSchema>;

export default DeviceGroupsList;
