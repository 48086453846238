import { z } from 'zod';

export const getCdliItemsSchema = z.array(
  z.object({
    retail_modified_item_id: z.number(),
    name: z.string(),
    price: z.string(),
    retail_item_id: z.number(),
    device_group_id: z.number(),
    receipt_text: z.string(),
    tax_category_id: z.number(),
    button_type: z.number(),
  }),
);

type CdliItemsSchema = z.infer<typeof getCdliItemsSchema>;

export default CdliItemsSchema;
