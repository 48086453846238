import { logError } from '@cfacorp/ctrl-platform-ui-core-utils';
import AppError from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/AppError';
import packageJson from '../../package.json';

interface Debug {
  context: string;
  response: AppError;
}

const logErrorToBugsnag = (errorClass: string, debug: Debug): void => {
  logError(errorClass, {
    ...debug,
    appName: 'Menu',
    appVersion: packageJson.version,
  });
};

export default logErrorToBugsnag;
