import { z } from 'zod';

export const taxCategory = z.array(
  z.object({
    parentRetailTaxCategoryId: z.number(),
    retailTaxCategoryLevel: z.number(),
    tax_category_id: z.number(),
    tax_category_name: z.string(),
  }),
);

type TaxCategorySchema = z.infer<typeof taxCategory>;

export default TaxCategorySchema;
