import { z } from 'zod';

export const localItem = z.object({
  buttonBackgroundColor: z.string(),
  buttonTextColor: z.string(),
  buttonTextString: z.string(),
  buttonType: z.number(),
  locationNumber: z.string(),
  menuFrameButtonId: z.string(),
  menuFrameId: z.string(),
  name: z.string(),
  visibility: z.string(),
});

export type LocalItem = z.infer<typeof localItem>;

export const getLocalItemsSchema = z.array(localItem);

type LocalItemsSchema = z.infer<typeof getLocalItemsSchema>;

export default LocalItemsSchema;
