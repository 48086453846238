import { useEffect, useState } from 'react';
import { useUserPermissions } from '@cfacorp/ctrl-platform-shared-react-components';

const useHasVenuesPerms = () => {
  const cfaPerms = useUserPermissions();
  const [hasVenuesPerms, setHasVenuesPerms] = useState(true);
  const [venuesPermsLoaded, setVenuesPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAuthorized =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.VENUES_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.VENUES_USER?.length;
      setHasVenuesPerms(isAuthorized);
      setVenuesPermsLoaded(true);
    }
  }, [cfaPerms]);

  return { hasVenuesPerms, venuesPermsLoaded };
};

export default useHasVenuesPerms;
