enum RouteConstants {
  HOME = '/',
  MENU_BASE_NAME = '/menu',
  POS_BASE_NAME = '/pos',
  EQUIPMENT_FAILURE = '/equipmentFailure',
  LIMITED_MENUS = '/limitedMenus',
  INGREDIENT_BASED_LOCKS = '/ingredientBasedLocks',
  MASTER_MENU = '/masterMenu',
  ITEM_LOCK = '/itemLock',
  PRICE_OVERRIDE = '/priceOverride',
  AUTO_SELL_CONDIMENTS = '/autoSellCondiments',
  CONDIMENT_LOOKUP = '/autoSellCondiments/condiment-lookup',
  VENUES = '/venues',
  VENUES_DETAIL = '/detail/:id',
  CUSTOM_ITEM_NAMES = '/customItemNames',
  CUSTOM_RECEIPT_TEXT = '/customReceiptText',
  LOCAL_ITEMS = '/localItems',
  LOCAL_ITEMS_DETAILS = '/localItems/edit/:menuFrameId/:menuFrameButtonId',
  TAX_EXEMPT = '/taxExemptAccounts',
  SPFB_CONFIG = '/spfbConfigurations',
  SPFB_CONFIG_GLOBAL_SETTINGS = '/spfbConfigurations/globalSettings',
  SPFB_CONFIG_POINT_OF_SALE_PROFILES = '/spfbConfigurations/pointOfSaleProfiles',
  SPFB_CONFIG_POINT_OF_SALE_PROFILES_DETAIL = '/spfbConfigurations/pointOfSaleProfiles/detail/:id',
  SPFB_CONFIG_POINT_OF_SALE_PROFILES_EDIT = '/spfbConfigurations/pointOfSaleProfiles/edit/:id',
  CUSTOM_DISCOUNTS = '/customDiscounts',
  CUSTOM_DISCOUNTS_DETAIL = '/customDiscounts/detail/:id',
  CUSTOM_DISCOUNTS_EDIT = '/customDiscounts/edit/:id',
  CUSTOM_DISCOUNTS_CREATE = '/customDiscounts/create/:name',
}

export default RouteConstants;
