import { useEffect, useState } from 'react';
import { useUserPermissions } from '@cfacorp/ctrl-platform-shared-react-components';

const useHasMenuPerms = () => {
  const cfaPerms = useUserPermissions();
  const [hasMenuPerms, setHasMenuPerms] = useState(true);
  const [menuPermsLoaded, setMenuPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAuthorized =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.MENU_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.MENU_USER?.length;
      setHasMenuPerms(isAuthorized);
      setMenuPermsLoaded(true);
    }
  }, [cfaPerms]);

  return { hasMenuPerms, menuPermsLoaded };
};

export default useHasMenuPerms;
