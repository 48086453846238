import { z } from 'zod';

export const autoSellItemSchema = z.object({
  retailModifiedItemId: z.number(),
  retailModifiedItemName: z.string(),
});

type AutoSellItem = z.infer<typeof autoSellItemSchema>;

export default AutoSellItem;
