export enum MenuDataPoints {
  ItemLocks = 'itemLocks',
  EquipmentFailure = 'equipmentFailure',
  PriceOverrides = 'priceOverrides',
  MenuActivations = 'menuActivations',
  EmergencyMenus = 'emergencyMenus',
  AutoSellCondiment = 'autoSellCondimentOverrides',
  CustomItemText = 'itemTextOverrides',
}

export const menuCartDataPointGroups = {
  [MenuDataPoints.ItemLocks]: 'Item Locks',
  [MenuDataPoints.EquipmentFailure]: 'Equipment Failure',
  [MenuDataPoints.PriceOverrides]: 'Price Overrides',
  [MenuDataPoints.MenuActivations]: 'Menu Activations',
  [MenuDataPoints.EmergencyMenus]: 'Emergency Menus',
  [MenuDataPoints.AutoSellCondiment]: 'Auto-Sell Condiments',
  [MenuDataPoints.CustomItemText]: 'Custom Item Names',
} as const;
